<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-if="hasRight('DEVICE_WRITE')"
        v-bind="$config.buttonAttrs.floating"
        :to="{ name: 'NewZone' }"
        title="Hozzáadás"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Zónák</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.name"
                @input="searchEventHandler"
                label="Megnevezés"
                clearable
                hide-details
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        calculate-widths
      >
        <template
          #[`item.actions`]="{ item }"
        >
          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            fab
            text
            small
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm"
            :to="{ name: 'ZoneEditor', params: { id: item.element_id } }"
            title="Módosítás"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            fab
            text
            small
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm"
            @click="deleteZone(item)"
            title="Törlés"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'ZoneList',
      fetchItems: this.fetchZones,
      dataTable: {
        options: {
          sortBy: ['name'],
        },
        headers: [
          { text: 'Megnevezés', value: 'name' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ],
      },
      search: {
        name: '',
        controller_id: null,
      },
      controllers: [],
    };
  },

  methods: {
    parseSearchQueries() {
      return {
        name: this.$route.query.name,
      };
    },

    async deleteZone(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<b>${item.name}</b> zóna törlésére készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        const response = await this.$http.get(`zones/delete/${item.element_id}`);
        this.fetchZones();
        if (response.status === 'OK') {
          this.$dialog.notify.info('A zóna törölve');
        }
      } catch (e) {
        console.error(e);
      }
    },

    async fetchZones() {
      this.loading = true;
      try {
        const response = await this.$http.post("zones/list", this.options);
        this.dataTable.items = response.zones;
        this.dataTable.itemsLength = response.zones_count;
        this.dataTable.options.page = this.$route.query.page*1 || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        name: this.search.name || ''
      };
    }
  }
};
</script>
